<template>
  <v-container
    id="vod"
    fluid
  >
    <v-layout
      row
      wrap
      align-center
      mb-3
    >
      <!-- Categories Select -->
      <v-flex
        text-xs-center
        xs12 order-xs3
        sm4 order-sm1
        md3 order-md1
      >
        <v-select
          :items="categories"
          v-model="filterCategory"
          @change="loadVideos"
          clearable
          color="#AC0000"
          dark
          solo-inverted
          item-text="name"
          item-value="id"
          label="Categories"
        ></v-select>
      </v-flex>


      <!-- Spacer -->
      <v-flex
        xs12 order-xs1
        sm4 order-sm2
        md6
      >
      </v-flex>

      <!-- Search text field -->
      <v-flex
        xs12 order-xs2
        sm4 order-sm3
        md3 order-md3
      >
        <v-text-field
          label="Search"
          prepend-inner-icon="search"
          v-model="search"
          clearable
          color="#AC0000"
          dark
          solo-inverted
          @keyup="loadVideos"
          @click:clear="loadVideos"
        >
        </v-text-field>
      </v-flex>
    </v-layout>


    <v-layout row>
      <v-flex>
        <!-- Videos -->
        <v-list two-line class="transparent">
          <template v-for="video in videos">
            <v-divider></v-divider>

            <v-list-tile
              :key="video.id"
              avatar
              :to="{ name:'vod-show', params: { id: video.id } }"
            >
              <v-list-tile-avatar>
                <img :src="video.avatar">
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title><h3 class="subheading font-weight-regular">{{video.name}}<span v-if="video.competition"> - {{video.competition}}</span></h3></v-list-tile-title>
                <v-list-tile-sub-title>
                  <span>{{video.category}} - </span>
                  {{ video.airedAt | moment("from", "now") }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>
          <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-throttle-delay="500" infinite-scroll-distance="0" infinite-scroll-immediate-check="false"></div>
          <div class="text-xs-center">
            <v-progress-circular v-if="busy" indeterminate color="#fff"></v-progress-circular>
          </div>
        </v-list>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
export default {
  name: 'vod',
  components: {
  },
  data: function () {
    return {
      // busy: false
    }
  },
  beforeMount() {
    this.loadVideos();
  },
  computed: {
    videoCount: get('vod/videoCount'),
    videos: get('vod/videos'),
    categories: get('vod/categories'),
    search: sync('vod/filters@search'),
    filterCategory: sync('vod/filters@category'),
    page: sync('vod/filters@page'),
    busy: sync('vod/busy')
  },
  methods: {
    loadVideos: call('vod/loadVideos'),
    loadMoreVideos: call('vod/loadMoreVideos'),
    loadMore() {
      if (this.videoCount !== (this.videos.length)) {
        this.busy = true;
        this.page += 1;

        this.loadMoreVideos().then(() => {
          this.busy = false;
        }).catch(error => {
          this.busy = false;
        })
      }
    }
  }
}
</script>

<style lang="scss">
#vod {
  max-width: 1200px;
  .v-list__tile {
    padding: 0px
  }
}
</style>
