<template>
  <v-flex grow v-if="stream" class="stream-content">
    <v-card dark color="black" elevation="0" :class="{ 'fill-height': theatreMode }" @mouseover.mouseenter="showBottomBar" v-if="stream.url">
      <div class="embed-responsive" :class="playeyContainerCss" :key="globalKey">
        <clappr v-if="stream.player == 'clappr'" :source="this.sessionSource" :secure-params="stream.secureParams"></clappr>
        <ustream v-if="stream.player == 'ustream'" :source="stream.url"></ustream>
        <youtube v-if="stream.player == 'youtube'" :source="stream.url"></youtube>
      </div>
      <!-- Actions in Theatre Mode -->
      <v-card-actions id="player-actions-theater" class="hidden-sm-and-down" v-if="theatreMode" v-visible="bottonBarVisible">
        <v-spacer></v-spacer>
        <v-tooltip bottom v-if="chatToggle">
          <template v-slot:activator="{ on }">
            <v-btn flat icon color="white" v-on="on" @click="theatreMode = !theatreMode" v-visible="bottonBarVisible">
              <v-icon grey>chrome_reader_mode</v-icon>
            </v-btn>
          </template>
          <span>Theatre Mode Off</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>


    <v-list subheader class="player-toolbar hide pt-2" v-if="!theatreMode">
      <program-progressbar v-if="stream.type == 'channel'" :stream="stream" />

      <v-list-tile avatar :class="{'channel-bar': (stream.type == 'channel')}">
        <v-list-tile-avatar>
          <img :src="stream.avatar">
        </v-list-tile-avatar>
        <info-bar :stream="stream" />

        <v-spacer></v-spacer>

        <!-- Action in Normal mode -->
        <div :class="{ 'chat-on-buttons': chatOn }">
          <stream-info-modal :stream="stream" />

          <v-tooltip top v-if="chatOn && chatToggle">
            <template v-slot:activator="{ on }">
              <v-btn flat icon color="grey" v-on="on" @click="theatreMode = !theatreMode" class="hidden-sm-and-down">
                <v-icon grey>chrome_reader_mode</v-icon>
              </v-btn>
            </template>

            <span>Theatre Mode</span>
          </v-tooltip>

          <v-tooltip top v-if="chatOn">
            <template v-slot:activator="{ on }">
              <v-btn flat icon color="grey" v-on="on" @click="chatToggle = !chatToggle">
                <v-icon grey>speaker_notes</v-icon>
              </v-btn>
            </template>

            <span v-if="chatToggle">Turn Chat Off</span>
            <span v-else>Turn Chat On</span>
          </v-tooltip>
        </div>
      </v-list-tile>
    </v-list>
  </v-flex>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import ProgramProgressbar from './stream-players/program-progressbar';
import InfoBar from './stream-players/info-bar';
import Clappr from './stream-players/clappr';
import Ustream from './stream-players/ustream';
import Youtube from './stream-players/youtube';
import StreamInfoModal from './stream-players/stream-info-modal';

export default {
  name: 'stream',
  components: { ProgramProgressbar, InfoBar, Clappr, Ustream, Youtube, StreamInfoModal },
  data: function () {
    return {
      bottonBarVisible: false,
      bottonBarTimer: null
    }
  },
  watch: {
    stream(newVal) { }
  },
  computed: {
         stream: get('streams/playing'),
lastUpdatedStamp: get('streams/lastUpdatedStamp'),
      globalKey: get('streams/globalKey'),
     chatToggle: sync('app/chatToggle'),
         chatOn: get('app/chatOn'),
    theatreMode: sync('app/theatreMode'),
    playeyContainerCss() {
      if (this.theatreMode) {
        return `embed-responsive-theater`
      } else {
        return `embed-responsive-contained`
      }
    },
    sessionSource() {
      if (this.stream.url.includes("u=")) {
        return `${this.stream.url}&st=${this.lastUpdatedStamp}`
      } else {
        return this.stream.url
      }
    }
  },
  methods: {
    showBottomBar() {
      if (this.chatToggle) {
        this.bottonBarVisible = true;
        clearTimeout(this.bottonBarTimer);
        this.bottonBarTimer = setTimeout( () => {
          this.bottonBarVisible = false;
        }, 3000);
      }
    },
    reloadStream() {
      this.$store.dispatch("streams/reloadStream", { id: this.stream.id });
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-on-buttons {
  white-space: nowrap; // Fix overlapping buttons on mobile
}

// remove space created by progress bar in channels
.channel-bar {
  margin-top: -3px;
}
</style>
