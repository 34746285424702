<template>
  <iframe
    width="100%"
    height="100%"
    :src="`https://www.youtube-nocookie.com/embed/${source}`"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  >
  </iframe>
</template>

<script>
export default {
  name: 'youtube',
  props: {
    source: {
      type: String,
      required: true,
      default: null
    }
  }
}
</script>
