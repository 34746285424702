import Vue from 'vue'
import { make } from 'vuex-pathify'
import VueCookies from 'vue-cookies'
import $router from '@router'


// Helpers
// =============================================================================
const helpers = {
  isTokenExpired: (getters) => {
    let expiresAt = getters.expireTokenStamp
    let now       = Vue.moment().unix() + 60 // a minute before now

    if (now >= expiresAt) {
      return true
    } else {
      return false
    }
  },
  groupBy: (array, key) => {
    const result = {}
    array.forEach(item => {
      if (!result[item[key]]){
        result[item[key]] = []
      }
      result[item[key]].push(item)
    })
    return result
  },
}


// State
// =============================================================================
const state = {
  lastUpdated: {e: 0, c: 0},
  globalKey: 0,
  playing: {},
  events:[],
  channels:[],
  groups:[]
}


// Getters
// =============================================================================
const getters = {
  ...make.getters(state),
  streams: (state, getters) => {
    return state.events.concat(state.channels)
  },
  findEventByName: (state, getters) => (name) => {
    return getters.streams.filter(stream => {
      return stream.name == name
    })
  },
  findEventById: (state, getters) => (id) => {
    return getters.streams.find(stream => stream.id == id)
  },
  events: (state, getters) => {
    return state.events.filter(stream => {
      return stream.type == "event"
    })
  },
  channels: (state, getters) => {
    return state.channels
  },
  leagues: (state, getters) => {
    let leagues_only = state.events.filter(stream => {
      return stream.type != "event" && stream.type != "channel"
    })
    return helpers.groupBy(leagues_only, 'type')
  },
  leagueNames: (state, getters) => {
    let names = []
    for (var key in getters.leagues) {
      names.push(key)
    }
    return names
  },
  expireTokenStamp: (state, getters) => {
    let foundStream = getters.channels[0] || getters.events[0]
    if (!foundStream) { return 0; }
    let url         = new URL(foundStream.url)
    let expiresAt   = url.searchParams.get("e")
    expiresAt       = parseInt(expiresAt)
    return expiresAt
  },
  lastUpdatedStamp: (state, getters) => {
    return Math.min(getters.lastUpdated.e, getters.lastUpdated.c)
  },
}


// Mutations
// =============================================================================
const mutations = {
  ...make.mutations(state),
  SET_PLAYING(state, {streamOptions, getters}) {
    let selectedStream = streamOptions;
    let stream         = getters.streams.find(stream => stream.id === selectedStream.id);
    let rescueStream   = getters.events[0] || getters.channels[0]; // Backup stream in case no stream was found
    let readyStream    = stream || rescueStream; // Use found stream or the backup

    if (state.playing) { readyStream.key = state.playing.key; } // Maintain current key if something is playing
    state.playing = readyStream;
    VueCookies.set("last_played", readyStream.id, -1, null, null, null, "Lax");
    sessionStorage.setItem('last_played', readyStream.id);
  },
  RESET_PLAYING(state) {
    state.playing = null
  },
  RENEW_KEY(state) {
    state.globalKey = Math.floor(Date.now() / 1000);
  },
}


// Actions
// =============================================================================
const actions = {
  // Gets stream data from server
  async fetchAll({ commit, getters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Vue.http.get('/stream_list', payload
      ).then(response => {
        if (payload.e == 't') { commit("SET_EVENTS", response.data.events) }
        if (payload.c == 't') { commit("SET_CHANNELS", response.data.channels) }

        resolve();
      }).catch(error => {
        console.log(error);
        if (error.response.status == 403) { Vue.http.redirectTo('/signin'); }
      })
    })
  },
  // Gets stream data from server and sets playing if it is current page
  async fetchAndLoad({ commit, getters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch("fetchAll", payload).then(() => {
        if ($router.history.current.name == "stream") {
          if(sessionStorage.getItem("last_played")) {
            let last_played = sessionStorage.getItem("last_played");
            commit('SET_PLAYING', { streamOptions: {id: last_played}, getters: getters } );
          } else if (VueCookies.isKey("last_played")) {
            let last_played = VueCookies.get("last_played");
            commit('SET_PLAYING', { streamOptions: {id: last_played}, getters: getters } );
          }
        }
        resolve()
      });
    })
  },
  // Navigate to stream page
  async navigateToStream({ commit, getters }, streamOptions) {
    return new Promise((resolve, reject) => {
      Vue.nprogress.start()
      if ($router.history.current.name != "stream") { $router.push({name: 'stream'}) }

      commit('SET_PLAYING', { streamOptions: {id: streamOptions.id}, getters: getters } )
      window.scrollTo(0, 0)
      Vue.nprogress.done()
    })
  },
  // Reload stream
  async reloadStream({ commit, getters, dispatch }, streamOptions) {
    return new Promise((resolve, reject) => {
      Vue.nprogress.start()
      commit('RENEW_KEY')
      Vue.nprogress.done()
    })
  },
  // Reset Stream
  async resetStream({ commit }, streamOptions) {
    return new Promise((resolve, reject) => {
      commit('RESET_PLAYING');
    })
  },
  // Check Auth
  async checkAuth({ commit, getters, dispatch, rootState, rootGetters }) {
    return new Promise((resolve, reject) => {
      Vue.http.get('/auth', { 'u': rootGetters['app/user'].id }
      ).then(response => {
        // Set default
        var shouldUpdate = { 'u': rootGetters['app/user'].id, 'e' : 'f', 'c' : 'f' }

        // Check if update is required
        if (response.data.e > getters.lastUpdated.e) { shouldUpdate['e'] = 't' }
        if (response.data.c > getters.lastUpdated.c) { shouldUpdate['c'] = 't' }

        // Check timestamp token is expired
        if (helpers.isTokenExpired(getters)) { shouldUpdate['e'] = shouldUpdate['c'] = 't' }

        // Fetch update if neeeded
        if (shouldUpdate['e'] == 't' || shouldUpdate['c'] == 't') { dispatch("fetchAndLoad", shouldUpdate); }
        commit("SET_LAST_UPDATED", response.data);
        resolve();
      }).catch(error => {
        console.log(error);
        if (error.response.status == 403 ) { Vue.http.redirectTo('/signin'); }
      })
    })
  },
}


// Export
// =============================================================================
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
