import Vue from 'vue'
import { make } from 'vuex-pathify'
import $router from '@router'

// initial state
const state = {
  busy: false,
  videoCount: null,
  videos: [],
  categories: [],
  filters: {
    search: "",
    category: undefined,
    page: 1
  },
  playing: {}
}

// getters
const getters = make.getters(state)

// mutations
const mutations = {
  ...make.mutations(state),

  ADD_VIDEOS(state, value) {
    state.videos.push(...value);
  },
  SET_PAGE(state, value) {
    state.filters.page =  value
  }
}

// actions
const actions = {
  async loadVideos({ getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_PAGE', 1); // Reset pages
      commit('SET_BUSY', true);

      Vue.http.get('/vod',
        getters.filters
      ).then(response => {
        commit("SET_CATEGORIES", response.data.categories);
        commit("SET_VIDEO_COUNT", response.data.videoCount);
        commit("SET_VIDEOS", response.data.videos);
        commit('SET_BUSY', false);
        resolve();
      }).catch(error => {
        console.log(error);
        commit('SET_BUSY', false);
      })
    })
  },
  async loadMoreVideos({ getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Vue.http.get('/vod',
        getters.filters
      ).then(response => {
        commit("SET_VIDEO_COUNT", response.data.videoCount);
        commit("ADD_VIDEOS", response.data.videos);
        resolve();
      }).catch(error => {
        console.log(error);
      })
    })
  },
  async loadVideo({ getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Vue.http.get(`/vod/${$router.history.current.params.id}`).then(response => {
        commit("SET_PLAYING", response.data.video);
        resolve();
      }).catch(error => {
        console.log(error);
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
