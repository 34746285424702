<template>
  <div>
    <h3 class="mb-3">Need Help?</h3>
    <p>If you have any questions you can reach us on <a :href="chat.discord.invite" target="_blank">Discord</a> or by opening a <a href="https://rapidvps.is/amember/helpdesk">ticket</a>.</p>
  </div>
  </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'

export default {
  name: 'iptv-help',
  props: {
  },
  data: () => ({
  }),
  beforeMount() {
  },
  computed: {
    chat: get('app/chat'),
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>
