import Vue from 'vue'
import store from '../store'
const tools = {
  parseRichTextImages(thisRef) {
    const attachments = thisRef.$el.querySelectorAll('action-text-attachment')

    for (const attachment of attachments) {
      const img = document.createElement('img')
      img.setAttribute('src', attachment.getAttribute('url'))
      img.setAttribute('sgid', attachment.getAttribute('sgid'))
      img.setAttribute('class', "action-text-image")
      attachment.parentNode.replaceChild(img, attachment)
    }
  },
  linksInNewWindow(thisRef) {
    const links = thisRef.$el.querySelectorAll('a')

    for (const link of links) {
      const newLink = document.createElement('a')
      newLink.setAttribute('href', link.getAttribute('href'))
      newLink.setAttribute('target', '_blank')
      newLink.setAttribute('class', 'guide-link')
      newLink.innerHTML = link.innerHTML
      link.parentNode.replaceChild(newLink, link)
    }
  },

  replaceWithEl(options) {
    const { selector, target, newElementName, hasBorder = false, attributes = {}, format = (count) => `Item ${count}` } = options

    let counter = 1 // Counter for replacements

    const container = document.querySelector(selector)
    if (!container) return

    const walker = document.createTreeWalker(container, NodeFilter.SHOW_TEXT, null)
    const nodes = []

    // Collect all text nodes first to avoid live TreeWalker issues
    while (walker.nextNode()) {
      nodes.push(walker.currentNode)
    }

    nodes.forEach((node) => {
      const parent = node.parentNode
      if (!parent) return

      let target_tag = String.raw `\[${target}\]`

      const content = node.textContent
      const regex = new RegExp(target_tag, 'g')
      const fragment = document.createDocumentFragment()
      let lastIndex = 0
      let match

      // Process all matches in the current text node
      while ((match = regex.exec(content)) !== null) {
        // Add text before the match
        if (match.index > lastIndex) {
          fragment.appendChild(document.createTextNode(content.substring(lastIndex, match.index)))
        }

        // Add <element> for the match
        const newElement = document.createElement(newElementName)
        // Set Attributes
        for(var key in attributes) { newElement.setAttribute(key, attributes[key]) }

        if (format) {
          newElement.textContent = format(counter)
        }

        // add optional hr
        if (hasBorder == true) {
          const newElementBorder = document.createElement('hr')
          newElementBorder.className = "content-divider v-divider theme--dark"
          fragment.appendChild(newElementBorder)
        }

        // add element
        fragment.appendChild(newElement)

        counter++ // Increment counter

        // Update lastIndex to the end of the current match
        lastIndex = match.index + match[0].length
      }

      // Add remaining text after the last match
      if (lastIndex < content.length) {
        fragment.appendChild(document.createTextNode(content.substring(lastIndex)))
      }

      // Replace the original text node with the fragment
      parent.replaceChild(fragment, node)
    })
  },

  replaceWithDynamicComponent(options) {
    const { selector, pattern, component, props } = options

    const container = document.querySelector(selector)
    if (!container) return

    const walker = document.createTreeWalker(container, NodeFilter.SHOW_TEXT, null)
    const nodes = []

    // Collect all text nodes first to avoid live TreeWalker issues
    while (walker.nextNode()) {
      nodes.push(walker.currentNode)
    }

    nodes.forEach((node) => {
      const parent = node.parentNode
      if (!parent) return

      const content = node.textContent
      const regex = new RegExp(pattern.source, pattern.flags + 'g')
      const fragment = document.createDocumentFragment()
      let lastIndex = 0
      let match

      // Process all matches in the current text node
      while ((match = regex.exec(content)) !== null) {
        // Add text before the match
        if (match.index > lastIndex) {
          fragment.appendChild(document.createTextNode(content.substring(lastIndex, match.index)))
        }

        // Create a Vue component for the match
        const ComponentClass = Vue.extend(component)
        const instance = new ComponentClass({
          store,
          propsData: {
            text: match[0], // Pass matched text as prop
            ...props        // Spread additional props
          }
        })
        instance.$mount() // Mount the component
        fragment.appendChild(instance.$el) // Append the rendered component

        // Update lastIndex to the end of the current match
        lastIndex = match.index + match[0].length
      }

      // Add remaining text after the last match
      if (lastIndex < content.length) {
        fragment.appendChild(document.createTextNode(content.substring(lastIndex)))
      }

      // Replace the original text node with the fragment
      parent.replaceChild(fragment, node)
    })
  }
}

export default tools;
