<template>
  <v-container fill-height>
    <v-flex align-center justify-center class="text-xs-center">
      <v-icon style="font-size:90px;" color="grey" class="mb-3">error_outline</v-icon>
      <h1 class="display-1">Not Found</h1>
    </v-flex>
  </v-container>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
</style>
