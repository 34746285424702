<template>
  <widgetbot-chat v-if="chat.kind == 'widgetbot'" />
  <twitch-chat v-else-if="chat.kind == 'twitch'" />
  <titan-chat v-else-if="chat.kind == 'titan'" />
  <div v-else></div>
</template>

<script>
import { get } from 'vuex-pathify'
import TwitchChat from '@views/chat/twitch-chat';
import TitanChat from '@views/chat/titan-chat';
import WidgetbotChat from '@views/chat/widgetbot-chat';

export default {
  name: "chat-sidebar",
  components: {
    TwitchChat,
    TitanChat,
    WidgetbotChat
  },
  data: function () {
    return {
    }
  },
  computed: {
    chatToggle: get('app/chatToggle'),
          chat: get('app/chat')
  },
}
</script>

<style lang="scss">
// $discord-brand-background: #36393f;

.v-window, .v-window__container {
  height: 100%;
}
.chat-sidebar {
  // background-color: $discord-brand-background;
  height: 100%;
  width: 100%;

  @media (min-width: 960px) {
    max-width: 350px;
    min-width: 350px;
  }

  .v-tabs__bar {
    // border-bottom: 1px solid #373739 !important;
    margin-top: -1px;
  }
}
.chat-sidebar-with-tabs {
  max-height: calc(100% - 48px);
}
.theatre-mode {
  .chat-sidebar-with-tabs {
    margin-top: -48px;
  }
}
.chat-container {
  // background-color: $discord-brand-background;
  height: 100%;
}
</style>
