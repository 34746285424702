<template>
  <v-dialog
    dark
    v-model="dialog"
    width="500"
    v-if="stream.type == 'channel' && CurrentProgram"
  >
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn flat icon color="grey" v-on="{ ...dialog, ...tooltip }">
            <v-icon grey>info</v-icon>
          </v-btn>
        </template>
        <span>Program Info</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title
        class="headline"
        primary-title
      >
        {{programTitle}}
      </v-card-title>

      <v-card-text>
        {{programDescription}}
        <br><br>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <!-- <v-btn-toggle v-model="toggle_exclusive">
          <v-btn flat>
            <v-icon>first_page</v-icon>
          </v-btn>
          <v-btn flat>
            <v-icon>last_page</v-icon>
          </v-btn>
        </v-btn-toggle> -->

        <v-spacer></v-spacer>
        <v-btn
          color="danger"
          flat
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get } from 'vuex-pathify'

  export default {
    props: {
      stream : {
        type: Object,
        required: true,
        default: null
      }
    },
    data () {
      return {
        dialog: false,

        toggle_exclusive: 0
      }
    },
    computed: {
      CurrentProgram() {
        return this.$store.get("epg/getCurrentProgram", this.stream.id)
      },
      programTitle() {
        if (this.CurrentProgram) {
          return this.CurrentProgram.title
        }
      },
      programDescription() {
        if (this.CurrentProgram) {
          return this.CurrentProgram.desc
        }
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
.theme--dark.v-sheet {
  background-color: #242424f0;
}
</style>
