<template>
  <v-container class="app-container" id="guides-show">
    <v-layout
      row
      wrap
      align-center
    >
      <v-flex>
        <!-- Start Device -->
        <div v-if="iptvDevice && iptvDevice.content" class="mb-4" id="guides-show-device">
          <v-list two-line class="transparent guides-show-header">
            <v-list-tile avatar>
              <v-avatar
                size="58"
                color="black"
              >
                <img :src="iptvDevice.brand" alt="avatar">
              </v-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{iptvDevice.title}}</v-list-tile-title>
                <v-list-tile-sub-title v-if="iptvDevice.subTitle">{{iptvDevice.subTitle}}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          <div id="guides-show-device-content" v-html='iptvDevice.content'></div>
        </div>

        <!-- Start app -->
        <div v-if="iptvApp" id="guides-show-app">
          <v-list two-line class="transparent guides-show-header">
            <v-list-tile avatar>
              <v-avatar
                size="54"
                color="black"
              >
                <img :src="iptvApp.brand" alt="avatar">
              </v-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{iptvApp.title}}</v-list-tile-title>
                <!-- <v-list-tile-sub-title v-if="iptvApp.subTitle">{{iptvApp.subTitle}}</v-list-tile-sub-title> -->
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          <div id="guides-show-app-content" v-html='iptvApp.content'></div>
        </div>

        <template>
          <div  class="mb-5">
            <v-divider class="content-divider"></v-divider>
            <iptv-help />
          </div>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import iptvLink from './iptv-link'
import iptvHelp from './iptv-help'
import { get, sync, call } from 'vuex-pathify'

export default {
  components: {
    iptvLink,
    iptvHelp,
  },
  data: () => ({
  }),
  beforeMount() {
    this.loadShow()
  },
  mounted() {
    this.$tools.parseRichTextImages(this)
    // this.$tools.linksInNewWindow(this)
  },
  updated() {
    this.$tools.parseRichTextImages(this)
    this.$tools.linksInNewWindow(this)
    this.findAndReplaceTags('#guides-show-device-content')
    this.findAndReplaceTags('#guides-show-app-content')
  },
  computed: {
    iptvDevice: get('guides/iptvDevice'),
    iptvApp: get('guides/iptvApp'),
    busy: sync('guides/busy'),
  },
  methods: {
    loadShow: call('guides/loadShow'),
    findAndReplaceTags(elSelector) {
      this.$tools.replaceWithEl({
        selector: elSelector, // container
        target: "hr", // search for [hr]
        newElementName:'hr', // wrap element
        attributes: { class: "content-divider v-divider theme--dark" },
        hasBorder: false,
        format: (count) => `` // Custom counter format
      })
      this.$tools.replaceWithEl({
        selector: elSelector, // container
        target: "step", // search for [step]
        newElementName:'h2', // wrap element
        attributes: { class: "guides-show-steps" },
        hasBorder: true,
        format: (count) => `Step ${count}:` // Custom counter format
      })
      this.$tools.replaceWithEl({
        selector: elSelector, // container
        target: "tip", // search for [tip]
        newElementName:'h2', // wrap element
        attributes: { class: "guides-show-steps" },
        hasBorder: true,
        format: (count) => `Tip ${count}:` // Custom counter format
      })
      this.$tools.replaceWithEl({
        selector: '#guides-show-device-content', // container
        target: "apk", // search for [apk]
        newElementName:'div', // wrap element
        attributes: { class: "guides-show-apk" },
        format: (count) => this.iptvApp.apkUrl // Custom counter format
      })
      this.$tools.replaceWithDynamicComponent({
        selector: elSelector, // Target container
        pattern: /\[playlist\]/, // Regex to match text
        component: iptvLink, // Specify the component to use
        props: { kind: 'playlist' } // Pass custom props
      })
      this.$tools.replaceWithDynamicComponent({
        selector: elSelector, // Target container
        pattern: /\[epg\]/, // Regex to match text
        component: iptvLink, // Specify the component to use
        props: { kind: 'epg' } // Pass custom props
      })
    },
  }
}
</script>

<style lang="scss">
#guides-show {
  em, em + a {
    color: #f95b5b;
    text-decoration-color: #f95b5b;
    font-weight: bold;
    font-style: normal;

  }

  .guides-show-header {
    .v-list__tile {
      padding: 0px;
    }
    .v-list__tile__title{
      font-size: 20px;
      font-weight: 500;
    }
    .v-list__tile__sub-title {
      font-size: 11px;
      overflow: visible;
      white-space: break-spaces;
      // margin-top: -4px;
      // margin-bottom: 4px;
      // line-height: 1;
      //
      @media (min-width: 600px) { // Tablet-Desktop
        font-size: 13px;
        // margin-top: -1px;
        // margin-bottom: 1px;
        // line-height: 24px;
      }
    }
    .v-avatar {
      margin-right: 15px;
    }
  }

  #guides-show-device, #guides-show-app {
    border-top: 1px solid grey;

    .v-divider:first-child {
      margin-top: 0px !important;
    }
  }
  .guides-show-steps {
    color: #919191;
  }

  .guides-show-apk {
    font-family: "SF Mono", monaco, "PT Menlo", menlo, Con­so­las, "Courier New", courier, monospace;
    background-color: white;
    color: black;
    padding: 6px 10px;
    border-radius: 5px;
    display: inline-block;
  }
}
</style>
