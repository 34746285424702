<template>
  <v-flex grow v-if="video.name">
    <v-card dark color="black">
      <storage-vod v-if="video.host == 'storage'" :source-id="video.hostToken" />
      <okru v-if="video.host == 'okru'" :source-id="video.hostToken"></okru>
      <mega v-if="video.host == 'mega'" :source-id="video.hostToken"></mega>
      <google-embed v-if="video.host == 'google'" :source-id="video.hostToken"></google-embed>
    </v-card>

    <v-list subheader class="player-toolbar hide pt-2">
      <v-list-tile avatar>
        <v-list-tile-avatar>
          <img :src="video.avatar">
        </v-list-tile-avatar>

        <v-list-tile-content>
          <v-list-tile-title>{{video.name}}<span v-if="video.competition"> - {{video.competition}}</span></v-list-tile-title>
          <v-list-tile-sub-title>
            <span>{{video.category}} - </span>
            {{ video.airedAt | moment("from", "now") }}
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-flex>
</template>

<script>
import { get, call } from 'vuex-pathify';
import StorageVod from './vod-players/storage-vod';
import okru from './vod-players/okru';
import mega from './vod-players/mega';
import googleEmbed from './vod-players/google-embed';

export default {
  name: 'vod-show',
  components: {
    StorageVod,
    okru,
    mega,
    googleEmbed
  },
  data: function () {
    return {
    }
  },
  beforeMount() {
    this.loadVideo();
  },
  computed: {
    video: get('vod/playing'),
  },
  methods: {
    loadVideo: call('vod/loadVideo'),
  }
}
</script>

<style lang="css" scoped>
</style>
