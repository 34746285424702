import Vue   from 'vue'
import Vuex  from 'vuex'
import pathify from 'vuex-pathify'
Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

// Import stores
import app from './modules/app'
import streams from './modules/streams'
import vod from './modules/vod'
import schedule from './modules/schedule'
import epg from './modules/epg'
import alerts from './modules/alerts'
import announcements from './modules/announcements'
import guides from './modules/guides'
import posts from './modules/posts'

const store = new Vuex.Store({
  plugins: [ pathify.plugin ],
  modules: {
    app,
    streams,
    vod,
    schedule,
    epg,
    alerts,
    announcements,
    guides,
    posts
  },

  strict: debug
})

export default store;
