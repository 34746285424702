<template>
  <v-container class="app-container" id="guides">
    <v-layout
      row
      wrap
      align-center
    >
      <v-flex>
        <template>
          <v-stepper v-model="guideStep">
            <v-stepper-header>
              <v-stepper-step :complete="guideStep > 1" step="1" color="#151515">Device</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="guideStep > 2" step="2" color="#151515">IPTV Apps</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <h3>Choose your Device:</h3>

                <v-card class="mb-4 mt-4" style="border-radius: 6px; overflow: hidden;">
                  <v-list class="" dark>
                    <template v-for="(device, index) in iptvDevices">
                      <v-list-tile :key="device.title" @click="selectDevice(device.id, device.slug)" >
                        <v-list-tile-avatar>
                          <img :src="device.brand">
                        </v-list-tile-avatar>
                        <v-list-tile-content class="white--text">
                          <v-list-tile-title>{{ device.title }}</v-list-tile-title>
                          <v-list-tile-sub-title v-if="device.subTitle">{{device.subTitle}}</v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-icon color="grey lighten-1">keyboard_arrow_right</v-icon>
                      </v-list-tile>
                      <v-divider v-if="index+1 !== iptvDevices.length"></v-divider>
                    </template>
                  </v-list>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="2">

                <h3>IPTV apps:</h3>

                <v-card class="mb-4 mt-4" style="border-radius: 6px; overflow: hidden;">
                  <v-list class="" dark>
                    <template v-for="(app, index) in selectedDeviceApps">
                      <v-list-tile :key="app.title" @click="$router.push({ name:'guides-show', params: { slug1: selectedDeviceSlug, slug2: app.slug } })">
                        <v-list-tile-avatar>
                          <img :src="app.brand">
                        </v-list-tile-avatar>
                        <v-list-tile-content class="white--text">
                          <v-list-tile-title>{{ app.title }}</v-list-tile-title>
                          <v-list-tile-sub-title v-if="app.subTitle">{{ app.subTitle }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                          <v-btn icon>
                            <v-icon color="grey lighten-1">keyboard_arrow_right</v-icon>
                          </v-btn>
                        </v-list-tile-action>
                      </v-list-tile>
                      <v-divider v-if="index+1 !== selectedDeviceApps.length"></v-divider>
                    </template>
                  </v-list>
                </v-card>

                <v-btn
                  color=""
                  @click="resetDevice()"
                >
                  <v-icon dark left>arrow_back</v-icon>Change Device
                </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>

        <template>
          <div  class="mb-5">
            <v-divider class="content-divider"></v-divider>
            <!-- <h2 class="mb-3">Need Help?</h2>
            <p>If you have any questions you can reach us on <a href="https://discord.gg/graqjHfFwH" target="_blank">Discord</a> or by opening a ticket in <a href="https://rapidvps.is/amember/helpdesk" target="_blank">support</a>.</p> -->
            <iptv-help />
          </div>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import iptvHelp from './iptv-help'

export default {
  components: {
    iptvHelp,
  },
  data: () => ({
    guideStep: 0,
    selectedDeviceId: null,
    selectedDeviceSlug: null,
  }),
  computed: {
    selectedDeviceApps(){
      if (this.selectedDeviceId) {
        return this.iptvApps.filter(iptvApp => {
          return iptvApp.devices.includes(this.selectedDeviceId)
        })
      }
    },
    iptvDevices: get('guides/iptvDevices'),
    iptvApps: get('guides/iptvApps'),
    busy: sync('guides/busy'),
  },
  beforeMount() {
    this.loadGuides();
  },
  methods: {
    loadGuides: call('guides/loadGuides'),
    selectDevice(deviceId, deviceSlug) {
      this.selectedDeviceId = deviceId
      this.selectedDeviceSlug = deviceSlug
      this.guideStep = 2
    },
    resetDevice() {
      this.selectedDeviceId = null
      this.selectedDeviceSlug = null
      this.guideStep = 1
    }
  }
}
</script>

<style lang="scss" >

#guides {
  .theme--dark.v-list {
    background: #151515;
  }
  .v-stepper {
    border-radius: 10px;
  }
  .v-list__tile {
    height: 55px;
  }
  .v-list__tile__sub-title {
    font-size: 11px;
    overflow: visible;
    white-space: break-spaces;
    margin-top: -4px;
    margin-bottom: 4px;
    line-height: 1;

    @media (min-width: 600px) { // Tablet-Desktop
      margin-top: -1px;
      margin-bottom: 1px;
      line-height: 1;
    }
  }
  .v-stepper__header {
    box-shadow: none;
  }
  .v-stepper__content {
    padding: 24px 10px 16px 10px;
    @media (min-width: 600px) { // Tablet-Desktop
      padding: 24px 24px 16px 24px;
    }
  }
}

</style>
