<template>
  <v-flex
    class="chat-sidebar fill-height"
  >
    <widgetbot
      :server="chat.discord.server"
      :channel="chat.discord.channel"
      height="99%"
      width="100%"
    ></widgetbot>
  </v-flex>
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: "widgetbot-chat",
  data: function () {
    return {
    }
  },
  mounted() {
    let widgetBotScript = document.createElement('script');
    widgetBotScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/@widgetbot/html-embed');
    document.body.appendChild(widgetBotScript);
  },
  computed: {
    chat: get('app/chat')
  },
}
</script>

<style lang="scss" scoped>
$discord-brand-background: #36393f;
.chat-sidebar   { background-color: $discord-brand-background; }
.chat-container { background-color: $discord-brand-background; }
</style>
