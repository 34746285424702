<template>
  <v-container class="app-container">
    <v-layout
      row
      wrap
      align-center
    >
      <v-flex>
        <h1 class="display-1">{{title}}</h1>
        <p v-if="cautionMessage" class="yellow--text mt-4">* This tutorial assumes you have already installed the app.</p>
        <v-divider class="content-divider"></v-divider>
        <template v-for="(item, index) in items">
          <div class="mb-5">
            <h2 class="mb-3">Step {{index+1}}:</h2>
            <p v-html="item.content"></p>
            <p v-if="item.displayAccessLink && iptv">Your Access link: <span class="inline-access-link">{{accessLink}}</span></p>
            <p v-if="item.displayEPGLink && iptv">Your EPG link: <span class="inline-access-link">{{epgLink}}</span></p>
            <img :src="item.image" alt="">
          </div>
          <v-divider v-if="index+1 !== items.length" class="content-divider"></v-divider>
        </template>

        <v-divider class="content-divider"></v-divider>
        <h1 v-if="tips">Tips</h1>
        <v-divider v-if="tips" class="content-divider"></v-divider>
        <template v-if="tips" v-for="(tip, index) in tips">
          <div class="mb-5">
            <h2 class="mb-3">Tip {{index+1}}:</h2>
            <p>{{tip.content}}</p>
            <img :src="tip.image" alt="">
          </div>
          <v-divider v-if="index+1 !== tips.length" class="content-divider"></v-divider>
        </template>
      </v-flex>

      <template v-if="more">
        <div  class="mb-5 mx-auto" style="text-align: center;">
          <p>{{more.text}}</p>
          <v-btn
            large
            round
            center
            color="info"
            class="v-btn-normalcase white--text mx-auto mt-5 mt-5"
            @click="$router.push({ name: more.route})"
          >
            Continue
            <v-icon right dark>keyboard_arrow_right</v-icon>
          </v-btn>
        </div>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'

export default {
  props: {
    getIptv: { required: false, default: false, type: Boolean },
    getPath: { required: true, default: null, type: String },
    cautionMessage: { required: false, default: false, type: Boolean }
  },
  data: () => ({
    title: "",
    items: [],
    tips: null,
    more: null
  }),
  mounted() {
    if (this.getIptv) {
      this.fetchIptvData();
    }
    if (this.getPath) {
      this.fetchData();
    }
  },
  computed: {
    iptv: get('app/iptv'),
    accessLink() {
      if (this.iptv) {
        return `${this.iptv.base_url}/${this.iptv.watch}/${this.iptv.key}`;
      } else {
        return false;
      }
    },
    epgLink() {
      if (this.iptv) {
        return `${this.iptv.base_url}/${this.iptv.epg}/${this.iptv.key}`;
      } else {
        return false;
      }
    }
  },
  methods: {
    fetchIptvData: call('app/fetchIptvData'),
    async fetchData() {
      return new Promise((resolve, reject) => {
        this.$http.get(this.getPath).then(response => {
          this.title = response.data.title;
          this.items = response.data.items;
          this.tips = response.data.tips;
          this.more = response.data.more;
          resolve();
        }).catch(error => {
          console.log(error);
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
