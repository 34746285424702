<template>
  <v-flex
    v-if="chat.twitch[1].id"
    class="chat-sidebar chat-sidebar-with-tabs"
  >
    <v-tabs
      grow
      color="#19191D"
      slider-color="#9B59FF"
      class="fill-height"
    >
        <v-tab
          v-for="chatChannel in chat.twitch"
          :key="chatChannel.name"
        >
          {{chatChannel.name}}
        </v-tab>

        <v-tab-item
          class="chat-container"
          v-for="chatChannel in chat.twitch"
          :key="chatChannel.name"
        >
            <iframe :src="`//www.twitch.tv/embed/${chatChannel.id}/chat?parent=drogon.tv`"
              id="chat_embed"
              frameborder="0"
              scrolling="no"
              height="99%"
              width="100%"
            >
            </iframe>
        </v-tab-item>
    </v-tabs>
  </v-flex>

  <v-flex
    v-else
    class="chat-sidebar fill-height"
  >
    <iframe :src="`//www.twitch.tv/embed/${chat.twitch[0].id}/chat?parent=drogon.tv`"
      id="chat_embed"
      frameborder="0"
      scrolling="no"
      height="99%"
      width="100%"
    >
    </iframe>
  </v-flex>
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: "twitch-chat",
  data: function () {
    return {
    }
  },
  computed: {
    chat: get('app/chat')
  },
}
</script>

<style lang="scss" scoped>
$twitch-brand-background: #19191D;
.chat-sidebar {
  background-color: $twitch-brand-background;
  .v-tabs__bar { border-bottom: 1px solid #373739 !important; }
}
.chat-container { background-color: $twitch-brand-background; }
</style>
