import Vue from 'vue'
import { make } from 'vuex-pathify'
import $router from '@router'

// initial state
const state = {
  busy: false,
  iptvDevices: [],
  iptvApps: [],
  iptvDevice: null,
  iptvApp: null,
  // selected: {
  //   g1: null,
  //   g2: null
  // },
}

// getters
const getters = {
  ...make.getters(state),
  // g1: (state, getters) => {
  //   return state.selected.g1
  // },
  // g2: (state, getters) => {
  //   return state.selected.g2
  // }
}
// mutations
const mutations = {
  ...make.mutations(state),
  // SET_G1(state, value) {
  //   state.selected.g1 = value
  // },
  // SET_G2(state, value) {
  //   state.selected.g2 = value
  // },
}

// actions
const actions = {
  async loadGuides({ getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true);

      Vue.http.get('/guides').then(response => {
        commit("SET_IPTV_DEVICES", response.data.iptvDevices);
        commit("SET_IPTV_APPS", response.data.iptvApps);

        commit('SET_BUSY', false);
        resolve();
      }).catch(error => {
        console.log(error);
        commit('SET_BUSY', false);
      })
    })
  },
  async loadShow({ getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Vue.http.get('/guides/display',
        { g1: $router.history.current.params.slug1, g2: $router.history.current.params.slug2 }
      ).then(response => {
        commit("SET_IPTV_DEVICE", response.data.iptvDevice);
        commit("SET_IPTV_APP", response.data.iptvApp);
        resolve();
      }).catch(error => {
        console.log(error);
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
