<template>
  <div class="embed-responsive embed-responsive-contained" >
    <iframe
      :src="`https://mega.nz/embed/${currectSource}`"
      width="100%"
      height="100%"
      frameborder="0"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'mega',
  props: {
    sourceId: {
      type: String,
      required: true,
      default: null
    }
  },
  computed: {
    currectSource() {
      if (this.sourceId.substring(0, 2) == '#!') { // Old Source Id
        var sourceId_without_first_chars = this.sourceId.substring(2);
        var tokens_array = sourceId_without_first_chars.split("!");
        var newSourceId = tokens_array[0] + '#' + tokens_array[1];
        return newSourceId;
      } else { // New Source ID
        return this.sourceId
      }
    }
  }
}
</script>
