<template>
  <v-container>
    <h1>Home</h1>
  </v-container>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
