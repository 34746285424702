<template>
  <!-- <div class="embed-responsive-item" > -->
    <iframe
      :src="source"
      style="border: 0;"
      webkitallowfullscreen
      allowfullscreen
      frameborder="no"
      width="100%"
      height="100%"
      >
    </iframe>
  <!-- </div> -->
</template>

<script>
export default {
  name: 'ustream',
  props: {
    source: {
      type: String,
      required: true,
      default: null
    }
  }
}
</script>
