<template>
  <div class="embed-responsive embed-responsive-contained">
    <div id="player-vod-container" class="embed-responsive-item"></div>
  </div>
</template>

<script>
export default {
  name: 'storage-vod',
  props: {
    sourceId: {
      type: String,
      required: true,
      default: null
    }
  },
  data: () => ({
    player: null,
    el: "player-vod-container"
  }),
  mounted() {
    const player = this.initClapprPlayer();
    this.player = player;
  },
  watch: {
    sourceId(newVal, oldVal) {
      // Set the Updated Player Options
      let newPlayerOptions = {
        source: newVal,
      };

      // Update source on stream change
      if (newVal !== oldVal) {
        this.player.configure(newPlayerOptions);
      }
    }
  },
  beforeDestroy() {
    this.player.destroy();
    this.player = null;
  },
  methods: {
    initClapprPlayer() {
      const player = new Clappr.Player({
        parentId: `#${this.el}`,
        height: "100%",
        width: "100%",
        source: this.sourceId,
        plugins: [
          ClapprPip.PipButton, ClapprPip.PipPlugin,
        ],
        autoPlay: true,
        mediacontrol: { seekbar: "#ac0000", buttons: "#fff" },
        disableErrorScreen: false,
        events: {
          onReady(e) {
            // disables click screen to pause stream
            var plugin = this.getPlugin('click_to_pause')
            plugin && plugin.disable()
          },
          onPause: function(e) {
            console.log("player-paused");
          },
          onStop: function(e) {
            console.log("player-stopped");
          },
          onEnded: function(e) {
            console.log("player-ended");
          },
          onError: function(e) {
            console.log("player-error");
            console.log(e);
          }
        }
      });

      return player;
    }
  }
};
</script>

<style lang="scss">
#player-vod-container {
  // Show seekbar
  .media-control-center-panel .bar-container {
    display:block !important;
  }
}
</style>
