<template>
  <div class="embed-responsive embed-responsive-contained" >
    <iframe
      :src="`https://drive.google.com/file/d/${sourceId}/preview`"
      width="100%"
      height="100%"
      frameborder="0"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'google-embed',
  props: {
    sourceId: {
      type: String,
      required: true,
      default: null
    }
  }
}
</script>
