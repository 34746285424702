import Vue from 'vue'
import { make } from 'vuex-pathify'
import VueCookies from 'vue-cookies'

// initial state
const state = {
  active: false,
  epg: []
}

// getters
const getters = {
  ...make.getters(state),
  getById: (state) => (id) => {
    return state.epg.find(channel => channel.id == id)
  },
  getCurrentProgram: (state, getters) => (id) => {
    let channel        = getters.getById(id)
    let now            = Vue.moment()
    let currentProgram = false

    if (channel) {
      channel.programmes.forEach((programme, index) => {
        let start = Vue.moment.unix(programme.start)
        let stop  = Vue.moment.unix(programme.stop)

        if (now.isBetween(start, stop)) {
          currentProgram = programme
        }
      })
    }

    return currentProgram
  },
  getNextProgram: (state, getters) => (id) => {
    let channel          = getters.getById(id)
    let currentProgram   = getters.getCurrentProgram(id)
    let nextProgram      = false

    if (channel && currentProgram !== "") {
      nextProgram = channel.programmes[currentProgram.index+1]
    }

    return nextProgram
  },
  getCurrentProgramProgress: (state, getters) => (id) => {
    let currentProgram = getters.getCurrentProgram(id)
    let now   = Vue.moment()
    let start = Vue.moment.unix(currentProgram.start)
    let stop  = Vue.moment.unix(currentProgram.stop)

    return ((now - start)/(stop - start)) * 100
  },
}


// mutations
const mutations = {
  ...make.mutations(state),
}


// actions
const actions = {
  async fetchEpg({ commit, getters, dispatch, rootState, rootGetters }) {
    return new Promise((resolve, reject) => {
      // Vue.http.get('/epg', { 'u': rootGetters['app/user'].id }
      Vue.http.get('/epg'
      ).then(response => {
        commit("SET_EPG", response.data.epg);
        commit("SET_ACTIVE", response.data.active);
        resolve();
      }).catch(error => {
        console.log(error);
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
