import Vue from 'vue'
import { make } from 'vuex-pathify'
import $router from '@router'

// initial state
const state = {
  busy: false,
  alerts: []
}

// getters
const getters = make.getters(state)

// mutations
const mutations = {
  ...make.mutations(state)
}

// actions
const actions = {
  async loadAlerts({ getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_BUSY', true);

      Vue.http.get('/alerts').then(response => {
        commit("SET_ALERTS", response.data.alerts);
        commit('SET_BUSY', false);
        resolve();
      }).catch(error => {
        console.log(error);
        commit('SET_BUSY', false);
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
