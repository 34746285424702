import Vue from 'vue'
import { make } from 'vuex-pathify'
import $router from '@router'

// initial state
const state = {
  busy: false,
  announcementCount: null,
  announcements: [],
  filters: {
    page: 1
  },
}

// getters
const getters = make.getters(state)

// mutations
const mutations = {
  ...make.mutations(state),

  ADD_ANNOUNCEMENTS(state, value) {
    state.announcements.push(...value);
  },
  SET_PAGE(state, value) {
    state.filters.page =  value
  }
}

// actions
const actions = {
  async loadAnnouncements({ getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_PAGE', 1); // Reset pages
      commit('SET_BUSY', true);

      Vue.http.get('/announcements',
        getters.filters
      ).then(response => {
        commit("SET_ANNOUNCEMENT_COUNT", response.data.announcementCount);
        commit("SET_ANNOUNCEMENTS", response.data.announcements);
        commit('SET_BUSY', false);
        resolve();
      }).catch(error => {
        console.log(error);
        commit('SET_BUSY', false);
      })
    })
  },
  async loadMoreAnnouncements({ getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Vue.http.get('/announcements',
        getters.filters
      ).then(response => {
        commit("SET_ANNOUNCEMENT_COUNT", response.data.announcementCount);
        commit("ADD_ANNOUNCEMENTS", response.data.announcements);
        resolve();
      }).catch(error => {
        console.log(error);
      })
    })
  },
  async loadAnnouncement({ getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Vue.http.get(`/announcements/${$router.history.current.params.id}`).then(response => {
        resolve();
      }).catch(error => {
        console.log(error);
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
