<template>
  <div>
    <v-card
      :id="cssId"
      class="iptv-links-card mb-3 elevation-5"
      dark
      max-width="600"
      style="border-radius: 15px;"
    >
      <v-card-title>
        <v-icon medium left>
          {{icon}}
        </v-icon>
        <span class="subheading font-weight-bold">{{heading}}</span>
      </v-card-title>

      <v-card-text class=" text-xs-center"> <div :class="sidebarClass">{{currentLink}}</div></v-card-text>
      <!-- <div style="display:block;height:30px;"></div> -->

      <v-card-actions>
        <v-list-tile class="grow">
        <v-list-tile-content>
           <v-btn @click="speakLink()" outline fab small><v-icon>volume_up</v-icon></v-btn>
        </v-list-tile-content>
          <v-layout
            align-center
            justify-end
            class="iptv-card-bottom-right"
          >
            <span class="mr-2" style="font-size:13px;">Case Sensitive</span>
          </v-layout>
        </v-list-tile>
      </v-card-actions>
    </v-card>
    <p class="iptv-link-warning" v-if="!sidebar">Please double check your URL. One mistake and it will not work.</p>
  </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'

export default {
  name: 'iptv-link',
  props: {
    kind: {
      required: false,
      type: String,
      default: 'playlist'
    },
    showHeader: {
      required: false,
      type: Boolean,
      default: true,
    },
    sidebar: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    cssId: null,
    heading: null,
    icon: null,
    currentNamespace: null
  }),
  beforeMount() {
    if (this.kind == 'playlist') {
      this.currentNamespace = 'w'
      this.cssId            = "iptv-card-playlist"
      this.heading          = "Your Playlist URL (M3U)"
      this.icon             = "playlist_play"
    } else {
      this.currentNamespace = 'g'
      this.cssId            = "iptv-card-epg"
      this.heading          = "Your EPG URL (XMLTV)"
      this.icon             = "menu_book"
    }
  },
  computed: {
    user: get('app/user'),
    domain: get('app/domain'),
    currentLink() {
      return `${this.domain}/${this.currentNamespace}/${this.user.token}`;
    },
    sidebarClass() {
      if(this.sidebar) {
        return "iptv-key iptv-key-sidebar"
      } else {
        return "iptv-key iptv-key-open"
      }
    }
  },
  methods: {
    speak(text, speed=1) {
      let msg = new SpeechSynthesisUtterance();
      msg.rate = speed;
      msg.text = text;
      msg.lang = 'en-US';
      msg.pitch = 0.2;
      window.speechSynthesis.speak(msg);
    },
    speakLink() {
      let domainParts = this.domain.split('.')
      let completeLink1 = domainParts[0]
      let completeLink2 = `dot`
      let completeLink3 = `${domainParts[1]}/${this.currentNamespace}/${this.user.token}`

      for (const letter of completeLink1) { this.speak(letter, 1); }
      this.speak(completeLink2, 0.7);
      for (const letter of completeLink3) { this.speak(letter, 1); }
    }
  }
}
</script>

<style lang="scss">
.iptv-links-card {
  .v-card__title {
    padding: 10px;
  }
  .v-card__actions {
    padding: 0px;
  }
  .v-list__tile {
    height: 44px;
    padding: 0 12px;
  }

  @media (max-width: 960px) { // Tablet-Desktop
    margin: 0 auto;
  }
  .v-btn--floating.v-btn--small {
    height: 28px;
    width: 28px;
    font-size: 10px;
  }

  .v-list__tile {
    .v-list__tile__title {
      font-size: 14px;
    }
    .v-list__tile__sub-title {
      font-size: 12px;
    }
  }
}
.iptv-key {
  font-weight: 900;
  // padding: 15px 0 !important;
  padding: 6px 10px;
  background-color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  border-radius: 10px;
  color: black;
  font-family: "SF Mono", monaco, "PT Menlo", menlo, Con­so­las, "Courier New", courier, monospace;
  letter-spacing: 1px;
  // white-space: nowrap;
  font-size: 10px;
}
.iptv-key-sidebar {
  @media (min-width: 600px) and (max-width: 960px) {
    font-size: 15px;
  }

  @media (min-width: 960px) and (max-width: 1100px) {
    font-size: 12px;
  }

  @media (min-width: 1100px) and (max-width: 1350px) {
    font-size: 13px;
  }

  @media (min-width: 1350px) {
    font-size: 15px;
  }
}
.iptv-key-open {
  @media (min-width: 600px) and (max-width: 960px) {
    font-size: 15px;
  }

  @media (min-width: 960px) and (max-width: 1100px) {
    font-size: 15px;
  }

  @media (min-width: 1100px) and (max-width: 1350px) {
    font-size: 15px;
  }

  @media (min-width: 1350px) {
    font-size: 15px;
  }
}
.iptv-card-bottom-right {
  margin: 0 !important;
}
#iptv-card-playlist {
  background-color: rgb(59, 136, 62);
  border-color: rgb(59, 136, 62);

  .v-icon {
    color: rgb(191, 255, 191);
    caret-color: rgb(191, 255, 191);
  }

  .v-btn {
    color: rgb(191, 255, 191);
    caret-color: rgb(191, 255, 191);
  }

  .subheading {
    color: rgb(191, 255, 191);
    caret-color: rgb(191, 255, 191);
  }
  .v-list__tile {
    color: rgb(191, 255, 191);
    caret-color: rgb(191, 255, 191);
  }
}
#iptv-card-epg {
  background-color: #455a64;
  border-color: #455a64;

  .v-icon {
    color: #90a4ae;
    caret-color: #90a4ae;
  }

  .v-btn {
    color: #b0bec5;
    caret-color: #b0bec5;
  }

  .subheading {
    color: #b0bec5;
    caret-color: #b0bec5;
  }
  .v-list__tile {
    color: #b0bec5;
    caret-color: #b0bec5;
  }
}
.iptv-link-warning {
  color: red;
}
</style>
