<template>
  <div class="embed-responsive embed-responsive-contained" >
    <iframe
      :src="`//ok.ru/videoembed/${sourceId}`"
      width="100%"
      height="100%"
      frameborder="0"
      allow="autoplay"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'okru',
  props: {
    sourceId: {
      type: String,
      required: true,
      default: null
    }
  }
}
</script>
