<template>
  <v-container
    v-if="showLanding"
    id="schedule"
    fluid
    :class="{ 'featured-container': isLandingPage }"
  >
    <!-- <ad-block-msg /> -->

    <!-- <v-alert
      :value="true"
      type="warning"
      class="mb-5"
    >
      We are currently performing maintenance on the service. Channels will be back shortly.
    </v-alert> -->

    <alerts />

    <v-layout row align-center>
      <v-flex center-content text-xs-center mb-5 v-if="isLandingPage">
        <h2 class="display-2 font-weight-bold mb-5">{{ title }}</h2>
      </v-flex>
    </v-layout>

    <v-layout
      row
      wrap
      align-center
      mb-5
    >
      <!-- Categories Select -->
      <v-flex
        text-xs-center
        xs12 order-xs3
        sm4 order-sm1
        md3 order-md1
      >
        <!-- <v-select
          :items="categories"
          v-model="filterCategory"
          @change="loadTimelines"
          clearable
          flat
          dense
          color="#AC0000"
          dark
          solo-inverted
          item-text="name"
          item-value="id"
          label="All"
        ></v-select> -->
      </v-flex>

      <!-- Upcoming Toggle -->
      <v-flex
        mb-4
        text-xs-center
        xs12 order-xs1
        sm4 order-sm2
        md6
      >
        <v-btn-toggle v-model="upcomingFilter" @change="loadTimelines" mandatory>
          <v-btn flat :value="true">Upcoming</v-btn>
          <v-btn flat :value="false">Past</v-btn>
        </v-btn-toggle>
      </v-flex>

      <!-- Search text field -->
      <v-flex
        xs12 order-xs2
        sm4 order-sm3
        md3 order-md3
      >
        <v-text-field
          label="Search schedule"
          prepend-inner-icon="search"
          v-model="search"
          clearable
          dark
          color="#AC0000"
          flat
          solo-inverted
          @keyup="loadTimelines"
          @click:clear="loadTimelines"
        />
      </v-flex>
    </v-layout>

    <!-- Timelines -->
    <v-layout row align-center v-for="(timeline, timeline_index) in timelines" :key="timeline_index" class="mt-5" :id="'timeline-index-'+timeline_index">
      <v-flex>
        <h2 class="subheading font-weight-bold grey--text mb-2 text--lighten-3">{{timeline.date | moment("dddd, MMMM Do, YYYY") }}</h2>
        <v-divider style="border-width: 2px 0 0 0;"></v-divider>

        <v-timeline dense v-if="timeline.events.length > 0" class="mb-4" :id="'timeline-'+timeline.date">
          <div v-for="(event, event_index) in timeline.events" :key="event_index">
            <group-event  v-if="Array.isArray(event)" :grouped-events="event" :key="event[0].id" :update-key="timestamp" />
            <single-event v-else :event="event" :key="event.id" />
          </div>
        </v-timeline>
      </v-flex>
    </v-layout>

    <div class="text-xs-center">
      <!-- <div>Backend: {{eventCount}}   -    Length: {{loadedEventCount}}</div> -->
      <v-btn
        v-if="eventCount !== loadedEventCount"
        @click="loadMore"
        :loading="busy"
        light
        color="grey"
      >
        Load More
      </v-btn>
    </div>

    <schedule-modal />
  </v-container>
</template>

<script>
import alerts from './alerts'
import adBlockMsg from './ad-block-msg'
import singleEvent from './schedule/single-event'
import groupEvent from './schedule/group-event'
import scheduleModal from './schedule/schedule-modal';
import { get, sync, call } from 'vuex-pathify'

export default {
  components: {
    alerts,
    adBlockMsg,
    singleEvent,
    groupEvent,
    scheduleModal,
  },
  props: {
    isLandingPage: { required: false, default: false, type: Boolean }
  },
  data: () => ({
    title: "Schedule",
    scheduleRefresher: null,
    lastSync: 0,
    syncInterval: 3600000, // update every 60 minutes
    timestamp: 0,
    dialog: false
  }),
  mounted() {
    this.updateSchedule();

    // based on https://stackoverflow.com/questions/13798516/javascript-event-for-mobile-browser-re-launch-or-device-wake
    this.scheduleRefresher = setInterval(() => {
      let date    = new Date()
      let now     = date.getTime()
      let minutes = date.getMinutes()

      if ( (now - this.lastSync.getTime()) > this.syncInterval || minutes == 0 && this.lastSync.setSeconds(0,0) != date.setSeconds(0,0) ) {
        this.updateSchedule()
      } else {
        this.timestamp = this.timestamp + 1
        // this.timestamp = + new Date()
      }
    }, 30000) // check every 30 seconds whether sync is needed
  },
  beforeDestroy() {
    window.clearInterval(this.scheduleRefresher);
  },
  computed: {
    status: get('schedule/status'),
    eventCount: get('schedule/eventCount'),
    loadedEventCount: get('schedule/loadedEventCount'),
    timelines: get('schedule/timelines'),
    categories: get('schedule/categories'),
    upcomingFilter: sync('schedule/filters@upcoming'),
    search: sync('schedule/filters@search'),
    filterCategory: sync('schedule/filters@category'),
    busy: sync('schedule/busy'),
    showLanding() {
      if (this.isLandingPage) {
        return this.status;
      } else {
        return true;
      }
    }
  },
  methods: {
    loadTimelines: call('schedule/loadTimelines'),
    loadMoreTimelines: call('schedule/loadMoreTimelines'),
    updateSchedule() {
      this.lastSync = new Date(); // set last sync to be now
      this.loadTimelines();
    },
    loadMore() {
      if (this.eventCount !== this.loadedEventCount) {
        this.busy = true;

        this.loadMoreTimelines().then(() => {
          this.busy = false;
        }).catch(error => {
          this.busy = false;
        })
      }
    }
  }
}
</script>

<style lang="scss">
#schedule {
  max-width: 1200px;
  padding-bottom: 100px;

  .v-timeline-item {
    padding-bottom: 10px; // margin in between events

    .v-timeline-item__body {
	     max-width: calc(100% - 50px);
    }

    .v-divider {
      border-color: rgba(255, 255, 255, 0.05);
    }
  }
  .live-sub-title {
    display: inline;
    vertical-align: middle;
    font-size: 12px;
  }

  .v-card {
    // border-bottom: 2px solid black !important;
  }
  .v-card::before, .v-card::after {
    display: none;
  }
  // Mobile
  @media (max-width: 960px) { }
  // Tablet and Desktop
  @media (min-width: 960px) { }
}
</style>
