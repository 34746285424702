<template>
  <v-flex
    class="chat-sidebar fill-height"
  >
    <iframe :src="`https://titanembeds.com/embed/${chat.discord.server}?theme=MetroEdge&scrollbartheme=minimal-dark&defaultchannel=${chat.discord.channel}`"
            height="99%"
            width="100%"
            frameborder="0"
    >
    </iframe>
  </v-flex>
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: "titan-chat",
  data: function () {
    return {
    }
  },
  computed: {
    chat: get('app/chat')
  },
}
</script>

<style lang="scss" scoped>
$discord-brand-background: #000;
.chat-sidebar   { background-color: $discord-brand-background; }
.chat-container { background-color: $discord-brand-background; }
</style>
