<template>
  <v-container
    id="iptv"
    class="app-container"
    grid-list-lg
  >
    <v-layout
      row
      wrap
    >
      <v-flex sm12 class="pa-2">
        <h1 class="display-1 font-weight-bold mb-4">Watch On Your TV</h1>
      </v-flex>
      <v-flex md7 class="pa-2">
        <template>
          <div  class="mb-5">
            <p>
              You can watch our streams by installing an IPTV app on your smart TV or streaming device.
              <!-- You can watch our streams on your TV by installing an IPTV app on your smart TV or streaming device. -->
            </p>
          </div>

          <div class="tv-frame" >
            <div class="tv-screen">
              <v-carousel
                hide-delimiters
                hide-controls
                :height="carouselHeight"
              >
                <v-carousel-item
                  v-for="(item,i) in carouselImages"
                  :key="i"
                >
                  <img
                    :src="item.src"
                    class="carousel-image"
                  />
                </v-carousel-item>
              </v-carousel>
            </div>
          </div>

          <!-- <div  class="mb-5">
            <p>
              If you're new to IPTV don't worry, we have plenty of guides to get you started.
            </p>
          </div> -->
        </template>

        <!-- <v-divider class="content-divider"></v-divider> -->

        <template>
          <div  class="mb-5 mx-auto" style="text-align: center;">
            <v-btn
              large
              round
              center
              color="info"
              class="white--text mx-auto mt-3"
              @click="$router.push({ name: 'guides'})"
            >
              How to Install
              <v-icon right dark>keyboard_arrow_right</v-icon>
            </v-btn>
          </div>
        </template>

        <template>
          <div  class="mb-5">
            <p>
              If you know what you're doing then you can find your IPTV URLs and the stream requirements
              <span class="hidden-sm-and-down">on the right.</span>
              <span class="hidden-md-and-up">below.</span>
            </p>
          </div>
        </template>
      </v-flex>

      <v-flex md5  class="pa-2">
        <!-- <template>
          <div  class="mb-3">
            <h3 class="mb-2">Your Access Links</h3>
          </div>
        </template> -->

        <v-layout row wrap>
          <v-flex md12>
            <iptv-link kind="playlist" sidebar />
            <iptv-link kind="epg" sidebar />

            <div class="">
              <v-divider class="sidebar-divider"></v-divider>
              <h3 class="mb-3">Stream Requirements</h3>
              <ul>
                <!-- <li>Update playlist at least every <em>12 hours</em></li> -->
                <li>Set your player to update every <em>12 hours</em> or less</li>
                <li>Max <em>2 devices</em> at once (no account sharing)</li>
              </ul>
              <v-divider class="sidebar-divider"></v-divider>
              <!-- <h3 class="mb-3">Need Help?</h3> -->
              <!-- <p>If you have any questions you can reach us on <a href="https://discord.gg/graqjHfFwH" target="_blank">Discord</a> or by opening a <a href="https://rapidvps.is/amember/helpdesk">ticket</a>.</p> -->
              <iptv-help />
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import iptvLink from './iptv/iptv-link'
import iptvHelp from './iptv/iptv-help'

export default {
  components: {
    iptvLink,
    iptvHelp,
  },
  data: () => ({
    carouselImages: [
      {
        src: '/tutorials/showcase/104585.jpg'
      },
      {
        src: '/tutorials/showcase/220575.jpg'
      },
      {
        src: '/tutorials/showcase/093795.jpg'
      },
      {
        src: '/tutorials/showcase/092818.jpg'
      },
      {
        src: '/tutorials/showcase/092530.jpg'
      }
    ]
  }),
  mounted() {
  },
  computed: {
    carouselHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '220px'
        case 'sm': return '300px'
        case 'md': return '346px'
        case 'lg': return '346px'
        case 'xl': return '346px'
      }
    },
  },
  methods: {
  },

}
</script>

<style lang="scss" scoped>
#iptv {
  @media (min-width: 600px) { // Tablet-Desktop
    font-size: 15px;
  }

  .sidebar-divider {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    @media (min-width: 600px) { // Tablet-Desktop
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }
  }
}

/* Carousel Image */
.carousel-image {
  width: 100%; /* Ensure the image takes the full height of the carousel */
  height: 100%; /* Ensure the image takes the full height of the carousel */
  // object-fit: contain; /* Maintain aspect ratio without cropping */
  object-fit: cover; /* Ensure images fill the screen */
}

$carousel-tv-color: #353535;

/* TV Frame */
.tv-frame {
  max-width: 658px;
  position: relative;
  margin: 0 auto 30px;
  background: $carousel-tv-color;
  border: solid $carousel-tv-color;
  border-width:  6px 6px 20px 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 50% 2%;
  border-bottom-right-radius: 50% 3%;
}
.tv-frame:after {
	content: '';
	display: block;
	position: absolute;
	bottom: -10px;
	left: 36%;
	height: .5%;
	width: 28%;
	background: #ddd;
	border-radius: 50%;
	box-shadow: 0 0 3px 0 white;
}

/* TV Screen */
.tv-screen {
  background: #000;
  border-radius: 10px;
  overflow: hidden;
}

/* Optional: Add a reflection effect to the screen */
.tv-screen::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
}

// @media all and (min-width: 960px) {
	.tv-screen {
		-webkit-animation: tvflicker 2s infinite alternate;
		-moz-animation:    tvflicker 2s infinite alternate;
		-o-animation:      tvflicker 2s infinite alternate;
		animation:         tvflicker 2s infinite alternate;
	}

	@-webkit-keyframes tvflicker {
	  0%   { box-shadow: 0 0 100px 0 rgba(200,235,255,0.4); }
	  100% { box-shadow: 0 0 95px 0 rgba(200,230,255,0.45); }
	}
	@-moz-keyframes tvflicker {
	  0%   { box-shadow: 0 0 100px 0 rgba(225,235,255,0.4); }
	  100% { box-shadow: 0 0 60px 0 rgba(200,220,255,0.6); }
	}
	@-o-keyframes tvflicker {
	  0%   { box-shadow: 0 0 100px 0 rgba(225,235,255,0.4); }
	  100% { box-shadow: 0 0 60px 0 rgba(200,220,255,0.6); }
	}
	@keyframes tvflicker {
	  0%   { box-shadow: 0 0 100px 0 rgba(225,235,255,0.4); }
	  100% { box-shadow: 0 0 60px 0 rgba(200,220,255,0.6); }
	}
// }

// TV inspired by:
// https://codepen.io/leon-ho/pen/AEOvWP

</style>
