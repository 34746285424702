<template>
  <v-container
    id="announcements"
    fluid
  >
    <alerts />

    <v-layout row>
      <v-flex>
        <!-- Announcements -->
        <v-list two-line class="transparent">
          <template v-for="announcement in announcements">
            <v-divider></v-divider>

            <v-list-tile
              :key="announcement.id"
              avatar
              :class="{ 'pinned-tile': displayPinned(announcement), 'unread-tile': isUnread(announcement.postedAt) }"
            >
              <v-list-tile-avatar color="#404040" size="42">
                <img :src="logoImgPath">
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title>
                  <div class="announcements-username">
                    Drogon.TV Team
                    <div class="subheading-time">{{ announcement.postedAt | moment(displayFormat(announcement.postedAt)) }}</div>
                  </div>
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  <div class="subheading-pinned" v-if="announcement.pin"><v-icon>push_pin</v-icon> Pinned</div>
                  <div class="subheading-content" v-html='announcement.content'></div>
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>
          <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-throttle-delay="500" infinite-scroll-distance="0" infinite-scroll-immediate-check="false"></div>
          <div class="text-xs-center">
            <v-progress-circular v-if="busy" indeterminate color="#fff"></v-progress-circular>
          </div>
        </v-list>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import alerts from './alerts'
import LogoImg from "@assets/images/avatar.png"
import { get, sync, call } from 'vuex-pathify'
export default {
  name: 'announcements',
  components: {
    alerts,
  },
  data: function () {
    return {
      logoImgPath: LogoImg,
      cachedLastReadAt: null,
      hasUnread: false
    }
  },
  beforeMount() {
    this.kind = 'announcements';
    this.loadAnnouncements();

    if (this.user.lastAnnouncementAt > this.user.announcementsLastRead) {
      this.hasUnread = true
      this.updateAnnouncementsLastRead();
    }
  },
  mounted() {
    this.$tools.parseRichTextImages(this)
    this.cachedLastReadAt = this.user.announcementsLastRead
  },
  updated() {
    this.$tools.parseRichTextImages(this)
  },
  computed: {
    user: sync('app/user'),
    announcementCount: get('announcements/announcementCount'),
    announcements: get('announcements/announcements'),
    page: sync('announcements/page'),
    busy: sync('announcements/busy'),

  },
  methods: {
    displayPinned(currentAnnouncement) {
      if (currentAnnouncement.pin && !this.hasUnread) {
        return true
      } else {
        return false
      }
    },
    isUnread(postedAtRaw) {
      if (this.$moment(postedAtRaw).unix() > this.cachedLastReadAt) {
        return true
      } else {
        return false
      }
    },
    displayFormat(postedAtRaw) {
      let now      = this.$moment()
      let postedAt = this.$moment(postedAtRaw)

      if (postedAt.isAfter(now.subtract(1, 'day'))) {
        return "[Today at] LT";
      } else if (postedAt.isAfter(now.subtract(2, 'day'))) {
        return "[Yesterday at] LT";
      } else {
        return "YYYY-MM-DD, LT";
      }
    },
    loadAnnouncements: call('announcements/loadAnnouncements'),
    loadMoreAnnouncements: call('announcements/loadMoreAnnouncements'),
    updateAnnouncementsLastRead: call('app/updateAnnouncementsLastRead'),
    loadMore() {
      if (this.announcementCount !== (this.announcements.length)) {
        this.busy = true;
        this.page += 1;

        this.loadMoreAnnouncements().then(() => {
          this.busy = false;
        }).catch(error => {
          this.busy = false;
        })
      }
    }
  }
}
</script>

<style lang="scss">
$brand-color: #ac0000;
$unread-color: $brand-color;
$pin-color: #ca8300;

#announcements {
  max-width: 1200px;

  .v-list__tile {
    padding: 15px 0 25px 10px;
    height: auto;
    align-items: normal;
    border-left: 3px solid transparent;

    &:hover {
      background-color: #151515;
    }
  }
  .announcements-username {
    color: $brand-color;
    font-weight: 500;
    font-size: 12px;
  }
  .pinned-tile {
    border-left: 3px solid $pin-color;
  }
  .unread-tile {
    border-left: 3px solid $unread-color;
  }
  .v-list__tile__sub-title {
    white-space: wrap;
  }
  .subheading-content {
    color: white;
    // font-size: 16px;
    padding: 0px 0 1px;
  }
  .subheading-time {
    display: inline;
    color: #848484;
    font-size: 10px;
    margin-left: 5px;
    font-weight: 400;
  }
  .subheading-pinned {
    color: $pin-color;
    font-size: 12px;
    margin-bottom: 5px;
    i {
      font-size: 13px;
      color: $pin-color;
    }
  }
  .v-avatar {
    margin-top: 4px;
  }
  .action-text-image {
    max-width: 100%;
  }

  @media (min-width: 450px) { // Tablet-Desktop
    .announcements-username {
      color: $brand-color;
      font-size: 14px;
    }
    .subheading-content {
      font-size: 15px;
    }
    .subheading-time {
      font-size: 11px;
    }
    .subheading-pinned {
      font-size: 12px;

      i {
        font-size: 13px;
      }
    }
  }
}
</style>
