<template>
  <v-layout row wrap fill-height :class="{ 'align-center': theatreMode }">
   <router-view />
   <chat-sidebar v-if="chatOn && chatToggle" />
 </v-layout>
</template>

<script>
import { get } from 'vuex-pathify'
import ChatSidebar from '@views/chat-sidebar';

export default {
  name: 'stream-layout',
  components: {
    ChatSidebar
  },
  data: function () {
    return {
    }
  },
  computed: {
         chatOn: get('app/chatOn'),
     chatToggle: get('app/chatToggle'),
    theatreMode: get('app/theatreMode')
  }
}
</script>

<style lang="scss">
.stream-content {
  flex-grow: 1;

  @media (min-width: 960px) {
    height: 100%;
  }
}
</style>
