import { render, staticRenderFns } from "./widgetbot-chat.vue?vue&type=template&id=6cb874ae&scoped=true&"
import script from "./widgetbot-chat.vue?vue&type=script&lang=js&"
export * from "./widgetbot-chat.vue?vue&type=script&lang=js&"
import style0 from "./widgetbot-chat.vue?vue&type=style&index=0&id=6cb874ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb874ae",
  null
  
)

export default component.exports