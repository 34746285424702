import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Home from '@views/home'
import IPTV from '@views/iptv'
import IPTVStart from '@views/iptv/start'
import Tutorial from '@views/iptv/tutorial'
import Guides from '@views/iptv/guides'
import GuidesShow from '@views/iptv/guides-show'
import VOD from '@views/vod'
import VodShow from '@views/vod-show'
import Schedule from '@views/schedule'
import StreamLayout from '@layouts/stream-layout'
import Stream from '@views/stream'
import NotFound from '@views/not-found'
import Announcements from '@views/announcements'

// Router
const router = new VueRouter({
  mode: 'history',
  routes: [
    // { name: 'home', path: '/app', component: Home },
    { name: 'home', path: '/app', component: Schedule },
    { name: 'iptv', path: '/app/iptv', component: IPTV },
    { name: 'guides', path: '/app/iptv/guides', component: Guides },
    { name: 'guides-show', path: '/app/iptv/:slug1/:slug2?', component: GuidesShow },
    { name: 'vod', path: '/app/vod', component: VOD },
    { name: 'vod-show', path: '/app/vod/:id', component: VodShow },
    { name: 'schedule', path: '/app/schedule', component: Schedule },
    { name: 'announcements', path: '/app/announcements', component: Announcements },
    {
      name: 'stream-layout',
      path: '/',
      component: StreamLayout,
      children: [
        { name: 'stream', path: '/app/stream', component: Stream },
      ]
    },
    { path: '/app*', component: NotFound }
  ]
});

router.beforeEach((to, from, next) => {
  Vue.nprogress.start()
  next()
})
//
router.afterEach((to, from) => {
  window.scrollTo(0,0); // Scroll to top on page change
  Vue.nprogress.done()
})

export default router
