<template>
  <v-progress-linear
    color="#ac0000"
    height="2"
    :value="progress"
    style="margin:0;position:relative;bottom:10px;"
  ></v-progress-linear>
</template>
<script>
import { get } from 'vuex-pathify'

export default {
  props: {
    stream : {
      type: Object,
      required: true,
      default: null
    }
  },
  data() {
    return {
      refreshInterval : null,
      progress: 0
    }
  },
  watch:{
    stream(newVal, oldVal) {
      this.calcProgress()
    }
  },
  mounted() {
    this.calcProgress()

    this.refreshInterval = setInterval(() => {
      this.calcProgress()
    }, 2000);
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  },
  computed: {
    currentProgram() {
      return this.$store.get('epg/getCurrentProgram', this.stream.id)
    }
  },
  methods: {
    calcProgress() {
      if (this.currentProgram) {
        let now   = Vue.moment()
        let start = Vue.moment.unix(this.currentProgram.start)
        let stop  = Vue.moment.unix(this.currentProgram.stop)

        this.progress = ((now - start)/(stop - start)) * 100
      }
    }
  }
}
</script>
