import Vue from 'vue'
import { make } from 'vuex-pathify'
import VueCookies from 'vue-cookies'

// initial state
const state = {
  domain: null,
  user: null,
  drawer: null,
  theatreMode: false,
  chat: { kind: 'off' },
  chatToggle: false,
  twitch: false,
}

// getters
const getters = {
  ...make.getters(state),
  chatOn(state) {
    if (state.chat.kind == 'off') {
      return false;
    } else {
      return true;
    }
  },
}


// mutations
const mutations = {
  ...make.mutations(state),
  SET_CHAT_TOGGLE(state, value) {
    state.chatToggle = value;
    VueCookies.set("chatToggle", value, -1, null, null, null, "Lax");
  },

  SET_ANNOUNCEMENT_READ(state) {
    state.user.announcementsLastRead = Vue.moment().unix();
  },
}


// actions
const actions = {
  async setCookies({ commit }) {
    return new Promise((resolve, reject) => {
      if(VueCookies.isKey("chatToggle")) { commit("SET_CHAT_TOGGLE", JSON.parse(VueCookies.get("chatToggle"))); }
      resolve();
    })
  },
  async fetchSettings({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Vue.http.get('/settings').then(response => {
        commit("SET_DOMAIN", response.data.domain);
        commit("SET_USER", response.data.user);
        commit("SET_CHAT", response.data.chat);
        resolve();
      }).catch(error => {
        console.log(error);
        if (error.response.status == 403) { Vue.http.redirectTo('/signin'); }
      })
    })
  },
  async fetchIptvData({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Vue.http.get('/iptv').then(response => {
        commit("SET_IPTV", response.data);
        resolve();
      }).catch(error => {
        console.log(error);
      })
    })
  },
  async updateAnnouncementsLastRead({ getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Vue.http.get(`/announcements/announcements_read`).then(response => {
        commit("SET_ANNOUNCEMENT_READ");
        resolve();
      }).catch(error => {
        console.log(error);
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
