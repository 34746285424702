<template>
  <div id="player-container" class="embed-responsive-item"></div>
</template>

<script>
export default {
  name: 'clappr',
  props: {
    source: {
      type: String,
      required: true,
      default: null
    },
    secureParams: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    player: null,
    el: "player-container",
    waitingStatus: true,
    timingOut: false,
    playerRetries: 0,
    playerRetryProcess: null
  }),
  mounted() {
    const player       = this.initClapprPlayer()
    this.playerRetries = 0
    this.player        = player
  },
  watch: {
    source(newVal, oldVal) {
      // Set the Updated Player Options
      let newPlayerOptions = {
        source: newVal,
        // hlsjsConfig: this.waitingScreen(this),
      };

      // if (this.secureParams) { newPlayerOptions.playback.hlsjsConfig.xhrSetup = this.secureOptions(this); } // Set secure link params
      // newPlayerOptions.playback.hlsjsConfig.xhrSetup = this.waitingScreen(this) // Set reloading for waiting screen

      // Update source on stream change
      if (newVal !== oldVal) {
        this.player.configure(newPlayerOptions);
      }
    }
  },
  beforeDestroy() {
    this.player.destroy();
    this.player = null;
    this.waitingStatus = true;
    this.playerRetries = 0
    if (this.playerRetryProcess) { clearTimeout(this.playerRetryProcess) }
  },
  computed: {
    retryWait() {
      if (this.playerRetries == 0) {
        return 0
      } else {
        return 10000 // 10 seconds
      }
    }
  },
  methods: {
    initClapprPlayer() {
      var thisRef = this
      let playerOptions = {
        parentId: `#${this.el}`,
        height: "100%",
        width: "100%",
        source: this.source,
        playback: {
          playInline: true,
           hlsjsConfig: {
             liveSyncDurationCount: 5,
             maxBufferLength: 15,
             // xhrSetup: this.waitingScreen(this)
           }
        },
        strings: {
            'en': {
             'live': 'live',
             'back_to_live': 'back to live',
             'disabled': 'Disabled',
             'playback_not_supported': 'Your browser does not support the playback of this video. Please try using a different browser.',
             'default_error_title': 'Waiting for signal...',
             'default_error_message': 'Stream will start automatically',
           }
        },
        mimeType: "application/x-mpegURL",
        plugins: [
          LevelSelector,
          ClapprPip.PipButton, ClapprPip.PipPlugin
        ],
        labels: {
          5: 'High 4',
          4: 'High 3',
          3: 'High 2',
          2: 'High',
          1: 'Med',
          0: 'Low',
        },
        levelSelectorConfig: {
          title: 'Quality',
          labels: {
              5: 'High 4',
              4: 'High 3',
              3: 'High 2',
              2: 'High',
              1: 'Medium',
              0: 'Low',
          },
          labelCallback(playbackLevel, customLabel) {
            // return customLabel + playbackLevel.level.height + 'p'; // High 720p
            return customLabel;
          },
        },
        autoPlay: true,
        mediacontrol: { seekbar: "#ac0000", buttons: "#fff" },
        events: {
          onReady(e) {
            // disables click screen to pause stream
            var plugin = this.getPlugin('click_to_pause')
            plugin && plugin.disable()
          },
          onPlay(e) {
            console.log("player-playing")
            thisRef.cleanPlay(thisRef)
          },
          onPause(e) {
            console.log("player-paused")
          },
          onStop(e) {
            console.log("player-stopped")
            thisRef.retryWorker(thisRef)
          },
          onEnded(e) {
            console.log("player-ended")
          },
          onError(e) {
            // console.log(e.description);
            // if ( e.description.includes("networkError") &&  e.description.includes(":0,") ) { thisRef.streamBlocked(thisRef) }
            // if ( e.description.includes("TimeOut") ) { thisRef.timingOut = true; }
            // if ( e.description.includes("networkError") && !/404|403|50|TimeOut/.test(e.description) && !thisRef.timingOut ) { thisRef.streamBlocked(thisRef) }
            if ( e.description.includes("networkError") && e.description.includes("403")) { thisRef.refreshMessage(thisRef) }
            if (thisRef.playerRetryProcess) { return; }
            console.log("player-error")
            thisRef.retryWorker(thisRef)
          }
        }
      };

      // if (this.secureParams) { playerOptions.playback.hlsjsConfig.xhrSetup = this.secureOptions(this); }  // Set secure link params
      // playerOptions.playback.hlsjsConfig.xhrSetup = this.waitingScreen(this) // Set reloading for waiting screen

      // init PLayer
      const player = new Clappr.Player(playerOptions);
      return player;
    },
    cleanPlay(thisRef) {
      if (thisRef.playerRetryProcess) {
        clearTimeout(thisRef.playerRetryProcess) // Remove retry loop
        thisRef.playerRetries = 0

        // Remove error screen
        const elements = document.getElementsByClassName('player-error-screen')
        while(elements.length > 0) { elements[0].parentNode.removeChild(elements[0]) }
      }
    },
    retryWorker(thisRef) {
      thisRef.playerRetryProcess = setTimeout( () => {
        if (thisRef.player) {
          // thisRef.player.configure(thisRef.player.options)
          thisRef.player.play()
          thisRef.playerRetries++
        }
      }, thisRef.retryWait)
    },
    streamBlocked(thisRef) {
      clearTimeout(thisRef.playerRetryProcess) // Remove retry loop
      thisRef.playerRetries = 0

      // Insert blocked message
      let screenTitle   = "Blocked"
      let screenMessage = "Something on your device or network is preventing playback.<br />" +
                          "Please adjust or disable adblockers, antivirus or firewalls that could be blocking the streams and then refresh the page.<br /><br />" +
                          'If you have further questions contact <a href="https://rapidvps.is/amember/helpdesk" target="_blank">support</a>.'

      document.getElementsByClassName("player-error-screen__title")[0].innerHTML = screenTitle
      document.getElementsByClassName("player-error-screen__message")[0].innerHTML = screenMessage
    },
    refreshMessage(thisRef) {
      clearTimeout(thisRef.playerRetryProcess) // Remove retry loop
      thisRef.playerRetries = 0

      // Insert blocked message
      let screenTitle   = "Refresh Page"
      let screenMessage = "Please refresh this page to continue playback."

      document.getElementsByClassName("player-error-screen__title")[0].innerHTML = screenTitle
      document.getElementsByClassName("player-error-screen__message")[0].innerHTML = screenMessage
    },
    // waitingScreen(thisRef) {
    //     return function(xhr, url) {
    //       if (url.indexOf('.mts') > -1) {
    //         console.log("Waiting: ", url);
    //         if (thisRef.waitingStatus == false) {
    //           console.log("Reloading: ", url);
    //           thisRef.waitingStatus = true
    //           thisRef.player.configure(thisRef.player.options)
    //         } else {
    //           thisRef.waitingStatus = true
    //         }
    //       } else if (url.indexOf('.ts') > -1) {
    //         console.log("Streaming: ", url);
    //         if (thisRef.waitingStatus == true) {
    //           console.log("Reloading: ", url);
    //           thisRef.waitingStatus = false;
    //           thisRef.player.configure(thisRef.player.options)
    //         }
    //       }
    //     }
    // },
    secureOptions(thisRef) {
      return function(xhr, url) {
        // Remove current params from url
        if (url.indexOf("?") !== -1) {
          url = url.substring(0, url.indexOf('?'));
        }

        // Add params
        if (url.indexOf("m3u8") !== -1) {
          url = url + '?' + thisRef.secureParams;
        }

        xhr.open('GET', url, true);
      }
    },
  }
};
</script>

<style lang="scss">
// fix for player in fullscreen becaise class name conflic
#player-container .container {
  max-width: none;
}
// Hide seekbar
.media-control-center-panel .bar-container {
  display:none !important;
}
.dvr-controls[data-dvr-controls] .live-info::before {
  margin-right: 5px;
  background-color: #ac0000;
  position:relative;
  bottom:1px;
}
.live-info {
  font-weight: 400;
  color: #ac0000;
  font-size: 12px !important;
  margin-left: 10px !important;
}

// Clappr - Remove play-stop buttons
.media-control-button.stopped { display: none !important; }
// Clappr - Make error screen backdrop black
.player-error-screen { background-color: #000 !important;}
// Clappr - Remove error code for users
.player-error-screen__code { display: none !important; }
// Clappr - Remove refresh button
.player-error-screen__reload { display: none !important; }
// Clappr - Remove large margin on top of error title
.player-error-screen__content { margin-top: 0 !important; }
// Clappr - Error title styling
.player-error-screen__title {
  // Mobile
  @media (max-width: 960px) {
    font-weight: 500 !important;
    line-height: 30px !important;
    font-size: 18px !important;
  }
  // Tablet and Desktop
  @media (min-width: 960px) {
    font-weight: 300 !important;
    line-height: 30px !important;
    font-size: 30px !important;
  }
}
.player-error-screen__message {
  color: gray;

  // Mobile
  @media (max-width: 960px) {
    margin-top: 0px !important;
    font-size: 12px !important;
  }
  // Tablet and Desktop
  @media (min-width: 960px) {
    margin-top: 10px !important;
  }
}

// Clappr - Fade Play button in to prevent flashing play button effect
@keyframes fadeElementIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.play-wrapper .poster-icon {
  animation: 6s ease-out 0s 1 fadeElementIn;
}
</style>
