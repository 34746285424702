<template>
  <v-layout row align-center v-if="alerts.length >= 1">
    <v-flex center-content text-xs-center mb-5>
      <v-alert
        v-for="alert in alerts"
        :key="alert.id"
        :value="true"
        :type="alert.kind"
        class="mb-12"
      >
        {{alert.content}}
      </v-alert>
    </v-flex>
  </v-layout>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'

export default {
  name: 'alerts',
  data: () => ({
  }),
  beforeMount() {
    this.loadAlerts();
  },
  computed: {
    alerts: get('alerts/alerts'),
    busy: sync('alerts/busy'),
  },
  methods: {
    loadAlerts: call('alerts/loadAlerts'),
  }
}
</script>

<style lang="scss">
.v-alert {
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
}

.warning {
  background-color: #b16200 !important;
}
</style>
