<template>
  <v-container class="app-container">
    <v-layout
      row
      wrap
      align-center
    >
      <v-flex>
        <h1 class="display-1 mb-5">Getting Started</sup></h1>
        <p class="mb-5">
          This section will show how to set up your Access Link on your set-top boxes or smart TV. Keep in mind that some devices may not have a way to play IPTV content because of restrictions set by the manufacturer.
        </p>

        <template>
          <!-- <v-layout row> -->
            <v-flex class="mb-5">
              <v-divider class="content-divider"></v-divider>
              <h2 class="mb-3">App Tutorials</h2>
              <p>How to configure your Access Link on apps available on app stores.</p>

              <v-card class="mb-4 mt-4" style="border-radius: 6px; overflow: hidden;">
                <v-list class="" dark>
                  <template v-for="(app, index) in apps">
                    <v-list-tile v-if="app.title" :key="app.title" @click="$router.push({ name: app.routeName })" >
                      <v-list-tile-content class="white--text">
                        <v-list-tile-title>{{ app.title }} <span class="deep-purple--text text--lighten-1">({{app.platforms}})</span></v-list-tile-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn icon>
                          <v-icon color="grey lighten-1">keyboard_arrow_right</v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider v-if="index+1 !== apps.length"></v-divider>
                  </template>
                </v-list>
              </v-card>
            </v-flex>
          <!-- </v-layout> -->
        </template>

        <template>
          <!-- <v-layout row> -->
            <v-flex class="mb-5">
              <v-divider class="content-divider"></v-divider>
              <h2 class="mb-3">Device Tutorials</h2>
              <p>How to install IPTV apps on devices that do not have any in the app store.</p>

              <v-card class="mb-4 mt-4" style="border-radius: 6px; overflow: hidden;">
                <v-list class="" dark>
                  <template v-for="(device, index) in devices">
                    <v-list-tile v-if="device.action" :key="device.title" @click="$router.push({ name: device.routeName })" >
                      <!-- <v-list-tile-action>
                        <v-icon>{{ device.action }}</v-icon>
                      </v-list-tile-action> -->
                      <v-list-tile-content class="white--text">
                        <v-list-tile-title>{{ device.title }}</v-list-tile-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn icon>
                          <v-icon color="grey lighten-1">keyboard_arrow_right</v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                    <v-divider v-if="index+1 !== devices.length"></v-divider>
                  </template>
                </v-list>
              </v-card>
            </v-flex>
          <!-- </v-layout> -->
        </template>

        <template>
          <div  class="mb-5">
            <v-divider class="content-divider"></v-divider>
            <h2 class="mb-3">Need Help?</h2>
            <p>If you have any questions or run into any problems you can reach us on <a href="https://discord.gg/UKtDpDF" target="_blank">Discord</a> or by opening a ticket in <a href="https://rapidvps.is/amember/helpdesk" target="_blank">support</a>.</p>
          </div>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// json data in: /views/iptv
export default {
  data: () => ({
    apps: [
      {
        title: 'Perfect Player',
        routeName: 'perfect_player',
        platforms: 'Android'
      },
      {
        title: 'GSE IPTV',
        routeName: 'gse',
        platforms: 'iOS, Apple TV, Android and MacOS'
      }
    ],
    devices: [
      {
        action: 'move_to_inbox',
        routeName: 'firestick',
        title: 'Amazon Fire TV Stick'
      }
    ],

  })
}
</script>

<style lang="scss" scoped>
</style>
