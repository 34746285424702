<template>
  <v-list-tile-content v-if="stream.start_at && !hasStarted">
    <v-list-tile-title v-html="stream.name"></v-list-tile-title>
    <v-list-tile-sub-title>
      <span>{{stream.competition}}</span>
    </v-list-tile-sub-title>
    <v-list-tile-sub-title v-if="this.startsIn" style="color: #e4b600;">
      Starts {{ startsIn }}
    </v-list-tile-sub-title>
  </v-list-tile-content>

  <v-list-tile-content v-else-if="stream.type == 'event' || !CurrentProgram">
    <v-list-tile-title v-html="stream.name"></v-list-tile-title>
    <v-list-tile-sub-title>
      <span>{{stream.competition}}</span>
    </v-list-tile-sub-title>
    <v-list-tile-sub-title class="live-sub-title">
      <span class="red-dot blink"></span> Live
    </v-list-tile-sub-title>
  </v-list-tile-content>

  <v-list-tile-content v-else>
    <v-list-tile-title>{{CurrentProgram}}</v-list-tile-title>
    <v-list-tile-sub-title>
      <span>{{stream.name}}</span>
    </v-list-tile-sub-title>
    <v-list-tile-sub-title>
      <span style="color:gray;" v-if="NextProgrammeTitle">Up Next:</span> <span>{{NextProgrammeTitle}}</span>
    </v-list-tile-sub-title>
  </v-list-tile-content>
</template>
<script>
import { get } from 'vuex-pathify'

export default {
  props: {
    stream : {
      type: Object,
      required: true,
      default: null
    }
  },
  data() {
    return {
      refreshInterval : null,
      startsIn: null,
      hasStarted: false
    }
  },
  mounted() {
    this.updateEventTimes()
    this.refreshInterval = setInterval(() => {
      this.updateEventTimes()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  },
  computed: {
    CurrentProgram() {
      let foundCurrentProgram = this.$store.get("epg/getCurrentProgram", this.stream.id)
      if (foundCurrentProgram) {
        return this.$store.get("epg/getCurrentProgram", this.stream.id).title
      }
    },
    NextProgrammeTitle() {
      let foundNextProgram = this.$store.get('epg/getNextProgram', this.stream.id)
      if (foundNextProgram) {
        return this.$store.get('epg/getNextProgram', this.stream.id).title
      }
    },
  },
  methods: {
    updateEventTimes() {
      this.updateStartsIn()
      this.updateHasStarted()
    },
    updateStartsIn() {
      if (this.stream.start_at) {
        this.startsIn = this.$moment.unix(this.stream.start_at).fromNow();
      }
    },
    updateHasStarted() {
      let startTime = this.$moment.unix(this.stream.start_at);
      let now = this.$moment();
      this.hasStarted = now.isAfter(startTime);
    }
  }
}
</script>
